var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { FILTERS_EQUAL, PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE } from "@kraftheinz/common";
import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Unit Percentage" } }, [_c("select-input", { key: "customerId", attrs: { "label": "Key Account Code", "reference": "promotion-planning.common.customers", "on-select-change": _vm.onCustomerChange, "all-in-value": true, "source": "id", "source-label": function(val) {
    return _vm.generateLabel(val, ["region", "keyAccountCode", "keyAccount"]);
  }, "placeholder": "Key Account Code", "rules": "required", "span": 24 } }), _c("select-input", { key: "productCode", attrs: { "label": "PPG", "advanced-filters": _vm.ppgFilters, "fetch-options-on-filter": true, "api-not-get-paging": true, "reference": "promotion-planning.unit-percentage.products", "source": "code", "source-label": function(val) {
    return _vm.generateLabel(val, ["code", "description"]);
  }, "placeholder": "PPG", "rules": "required", "span": 24 } }), _c("number-input", { key: "sellin", attrs: { "label": "Sellin %", "placeholder": "Sellin %", "rules": "required|min_value:0|max_value:100", "span": 8 } }), _c("number-input", { key: "sellthrough", attrs: { "label": "Sellthrough %", "placeholder": "Sellthrough %", "rules": "required|min_value:0|max_value:100", "span": 8 } }), _c("number-input", { key: "sellout", attrs: { "label": "Sellout %", "placeholder": "Sellout %", "rules": "required|min_value:0|max_value:100", "span": 8 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditUnitPercentage",
  inject: ["crud"],
  mixins: [PromoUtils],
  data() {
    return {
      selectedCustomer: {}
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity() || {};
    },
    ppgFilters() {
      if (!this.selectedCustomer.id)
        return null;
      return [
        [
          "CustomerId",
          { operator: FILTERS_EQUAL, value: this.selectedCustomer.id }
        ]
      ];
    }
  },
  watch: {
    entity(newVal) {
      this.getValue(newVal, true);
    }
  },
  methods: {
    onCustomerChange(value) {
      this.crud.updateEntity("editForm", {
        resourceKey: "customerId",
        value: value.id
      });
      this.getValue(value);
    },
    getValue(value, isFromDetails = false) {
      const data = {
        id: isFromDetails ? value.customerId : value.id,
        region: value.region
      };
      this.selectedCustomer = __spreadValues({}, data);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditUnitPercentage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-unit-percentage" }, [_c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.unit-percentage.products", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.unit-percentage", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/unit-percentage", "page": _vm.page } }, [_c("edit-unit-percentage")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditUnitPercentage
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
